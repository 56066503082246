<script>
import router from '@/router';

export default {
    data() {
        return {
            showModalPrivacy: false,
            showModalTerms: false
        }
    },
    methods: {
        toggleModalPrivacy: function () {
            this.showModalPrivacy = !this.showModalPrivacy;
        },
        toggleModalTerms: function () {
            this.showModalTerms = !this.showModalTerms;
        }
    }
}
</script>

<template>

    <div>
        <div v-if="showModalPrivacy"
            class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-auto my-6 mx-auto max-w-6xl max-h-[90vh] p-4 sm:p-6 md:p-8 lg:p-10">
                <!--content-->
                <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-3xl font-semibold">
                            Privacy Policy
                        </h3>
                        <button
                            class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            v-on:click="toggleModalPrivacy()">
                            <span
                                class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto overflow-y-auto max-h-[60vh]">

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">1. Introduzione</h2>
                            <p class="text-base leading-relaxed">
                                Questa Policy & Privacy descrive come raccogliamo, utilizziamo e proteggiamo le tue
                                informazioni personali. Utilizzando il nostro sito, acconsenti alla raccolta e all'uso
                                delle tue informazioni in conformità con questa politica.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">2. Informazioni che Raccogliamo</h2>
                            <p class="text-base leading-relaxed">
                                Raccogliamo informazioni personali che ci fornisci volontariamente quando utilizzi i
                                nostri servizi o ci contatti. Queste informazioni possono includere nome, indirizzo
                                email, numero di telefono e qualsiasi altra informazione che decidi di condividere con
                                noi.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">3. Uso delle Informazioni</h2>
                            <p class="text-base leading-relaxed">
                                Utilizziamo le informazioni raccolte per:
                            </p>
                            <ul class="list-disc list-inside pl-4">
                                <li>Fornire e migliorare i nostri servizi.</li>
                                <li>Rispondere alle tue richieste e domande.</li>
                                <li>Inviarti aggiornamenti e comunicazioni relative ai nostri servizi.</li>
                                <li>Analizzare l'uso del nostro sito per migliorare la nostra offerta.</li>
                            </ul>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">4. Condivisione delle Informazioni</h2>
                            <p class="text-base leading-relaxed">
                                Non condividiamo le tue informazioni personali con terze parti, eccetto quando richiesto
                                dalla legge o per proteggere i nostri diritti.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">5. Sicurezza delle Informazioni</h2>
                            <p class="text-base leading-relaxed">
                                Adottiamo misure di sicurezza adeguate per proteggere le tue informazioni personali da
                                accessi non autorizzati, alterazioni, divulgazioni o distruzioni.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">6. Diritti dell'Utente</h2>
                            <p class="text-base leading-relaxed">
                                Hai il diritto di accedere, correggere o cancellare le tue informazioni personali in
                                nostro possesso. Per esercitare questi diritti, ti preghiamo di contattarci utilizzando
                                le informazioni di contatto fornite sul sito.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">7. Modifiche alla Policy</h2>
                            <p class="text-base leading-relaxed">
                                Ci riserviamo il diritto di modificare questa Policy & Privacy in qualsiasi momento. Le
                                modifiche saranno pubblicate su questa pagina e diventeranno effettive al momento della
                                pubblicazione.
                            </p>
                        </section>

                        <section>
                            <h2 class="text-xl font-semibold mb-2">8. Contatti</h2>
                            <p class="text-base leading-relaxed">
                                Per domande o ulteriori informazioni sulla nostra Policy & Privacy, puoi contattarci
                                all'indirizzo email
                                <a href="mailto:info@pucciefigli.com"
                                    class="text-blue-500 hover:underline">info@pucciefigli.com</a>.
                            </p>
                        </section>
                    </div>
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button" v-on:click="toggleModalPrivacy()">
                            Chiudi
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showModalTerms"
            class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-auto my-6 mx-auto max-w-6xl max-h-[90vh] p-4 sm:p-6 md:p-8 lg:p-10">
                <!--content-->
                <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-3xl font-semibold">
                            Termini di Servizio
                        </h3>
                        <button
                            class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            v-on:click="toggleModalTerms()">
                            <span
                                class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto overflow-y-auto max-h-[60vh]">

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">1. Accettazione dei Termini </h2>
                            <p class="text-base leading-relaxed">
                                Utilizzando il nostro sito web, accetti di rispettare e di essere vincolato dai presenti
                                Termini di Servizio. Se non sei d'accordo con qualsiasi parte di questi termini, ti
                                preghiamo di non utilizzare il nostro sito.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">2. Descrizione dei Servizi</h2>
                            <p class="text-base leading-relaxed">
                                Forniamo servizi di restauro e creazione di persiane, infissi e pergolati. Le
                                informazioni e i servizi disponibili su questo sito sono offerti senza garanzie di alcun
                                tipo.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">3. Modifiche ai Termini </h2>
                            <p class="text-base leading-relaxed">
                                Ci riserviamo il diritto di modificare questi Termini di Servizio in qualsiasi momento.
                                Le modifiche diventeranno effettive al momento della loro pubblicazione sul sito. È tua
                                responsabilità controllare periodicamente i Termini di Servizio per eventuali
                                cambiamenti.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">4. Uso del Sito</h2>
                            <p class="text-base leading-relaxed">
                                L'uso del sito deve essere legale e conforme ai presenti Termini di Servizio. Non è
                                consentito utilizzare il sito per qualsiasi scopo illecito o proibito da questi termini.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">5. Proprietà Intellettuale</h2>
                            <p class="text-base leading-relaxed">
                                Tutti i contenuti presenti sul sito, inclusi testi, immagini, grafica e loghi, sono di
                                nostra proprietà o concessi in licenza. È vietato riprodurre, distribuire o utilizzare
                                in qualsiasi altro modo questi contenuti senza il nostro previo consenso scritto.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">6. Limitazione di Responsabilità</h2>
                            <p class="text-base leading-relaxed">
                                Non saremo responsabili per eventuali danni diretti, indiretti, incidentali, speciali o
                                consequenziali derivanti dall'uso o dall'incapacità di utilizzare il nostro sito o i
                                nostri servizi.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">7. Legge Applicabile</h2>
                            <p class="text-base leading-relaxed">
                                I presenti Termini di Servizio sono regolati e interpretati in conformità con le leggi
                                italiane. Qualsiasi controversia derivante da o relativa a questi termini sarà di
                                competenza esclusiva dei tribunali italiani.
                            </p>
                        </section>
                    </div>
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button" v-on:click="toggleModalTerms()">
                            Chiudi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="bg-white dark:bg-gray-900">
        <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-6"> <!-- max-w-screen-xl -->
            <div class="md:flex md:justify-between">
                <div class="mb-6 md:mb-0">
                    <a href="https://pucciefigli.com/" class="flex items-center">
                        <img src="@/assets/logo.png" class="h-14 me-3" alt="FlowBite Logo" />
                        <span class="self-center text-2xl font-semibold whitespace-nowrap">Pucci<span class="text-blue-500">&amp;</span>Figli</span>
                    </a>
                    <p class="text-sm m text-gray-800 dark:text-gray-300 p-2 leading-relaxed">
                        Pucci & Figli nasce nel 1998 come azienda Leader nel montaggio e fornitura di tende da sole,
                        infissi...
                    </p>
                    <p class="text-sm text-gray-800 dark:text-gray-300 p-2 leading-relaxed">
                        P.IVA: 01721190559
                    </p>
                    <p class="text-sm text-gray-800 dark:text-gray-300 p-2 leading-relaxed">
                        PEC: pucciterni@pec.it
                    </p>
                    <p class="text-sm text-gray-800 dark:text-gray-300 p-2 leading-relaxed">
                        Cellulare: +<a href="tel:+393758881009" class="text-black hover:underline">39 375 8881009</a>
                    </p>
                </div>
                <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Servizi</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-4">
                                <router-link to="/extra-servizi/2" class="hover:underline">Tende da sole</router-link>
                            </li>
                            <li>
                                <router-link to="/extra-servizi/1" class="hover:underline">Infissi</router-link>
                            </li>
                            <!--
                            <li>
                                <a href="tel:+393884585068" class="text-black-500 hover:underline"><router-link to="/extra-servizi/1" class="hover:underline">+39 375 8881009</router-link></a>
                            </li>
                            -->
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Informazioni</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-4">
                                <router-link to="/contattaci" class="hover:underline">Contattaci</router-link>
                            </li>
                            <li>
                                <router-link to="/contattaci" class="hover:underline">Diventa un
                                    rivenditore</router-link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Seguici su</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-4">
                                <a href="https://www.instagram.com/pucciefigli" class="hover:underline ">Instagram</a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/p/Pucci-Figli-61557651903938/"
                                    class="hover:underline">Facebook</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">INFO</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-4">
                                <a v-on:click="toggleModalPrivacy()" class="hover:underline">Privacy Policy</a>
                            </li>
                            <li>
                                <a v-on:click="toggleModalTerms()" class="hover:underline">Terms &amp; Conditions</a>
                            </li>
                        </ul>
                    </div>                    
                </div>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div class="sm:flex sm:items-center sm:justify-between">
                <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a
                        href="https://pucciefigli.com" class="hover:underline">Pucci&Figli™</a> - Designed & Created by
                    <a href="https://tooga.it">TOOGA</a>
                </span>
                <div class="flex mt-4 sm:justify-center sm:mt-0">
                    <a href="https://www.instagram.com/pucciefigli"
                        class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                        <div class="icon-padding">

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-instagram" viewBox="0 0 16 16">
                                <path
                                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                            </svg>
                        </div>
                    </a>

                    <a href="https://www.facebook.com/pucciefigli"
                        class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                        <div class="icon-padding">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-facebook" viewBox="0 0 16 16">
                                <path
                                    d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </footer>

</template>

<style scoped>
.icon-padding {
    padding: 5px;
}
</style>