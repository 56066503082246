<template>
  <BackgroundVideo />
  <div class="services-intro">
    <h1>I Nostri Servizi</h1>
    <p>Scopri i servizi che offriamo per aiutarti a raggiungere i tuoi obiettivi.</p>
  </div>
  <AppSliderService />
  <StatisticheDinamiche />
  <div class="services-intro">
    <h1>Dicono di noi</h1>
    <p class="mb-4">Scopri cosa dicono i nostri clienti dei nostri servizi e del nostro lavoro.</p>
    <a class="p-2" href="https://g.co/kgs/fjuKUsF">
    <button
        class="bg-indigo-500 text-white p-4 rounded-lg font-semibold hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
        Consulta le recensioni
      </button>
    </a>
  </div>
  <div>
    <GoogleReviews />
  </div>

</template>

<script>
import StatisticheDinamiche from '@/components/home/StatisticheDinamiche.vue';
import AppSliderService from '@/components/home/AppSliderService.vue';
import BackgroundVideo from '@/components/home/BackgroundVideo.vue';
import GoogleReviews from '@/components/home/GoogleReviews.vue';

export default {
  name: 'HomeView',
  components: {
    AppSliderService,
    BackgroundVideo,
    GoogleReviews,
    StatisticheDinamiche,
  },
};
</script>

<style>
.services-intro {
  text-align: center;
  margin-bottom: 20px;
  padding: 40px;
}

.services-intro h1 {
  font-size: 2em;
  color: #333;
}

.services-intro p {
  font-size: 1.2em;
  color: #666;
}
</style>