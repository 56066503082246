<template>
            <div v-if="showModalPrivacy"
            class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-auto my-6 mx-auto max-w-6xl max-h-[90vh] p-4 sm:p-6 md:p-8 lg:p-10">
                <!--content-->
                <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-3xl font-semibold">
                            Privacy Policy
                        </h3>
                        <button
                            class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            v-on:click="toggleModalPrivacy()">
                            <span
                                class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto overflow-y-auto max-h-[60vh]">

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">1. Introduzione</h2>
                            <p class="text-base leading-relaxed">
                                Questa Policy & Privacy descrive come raccogliamo, utilizziamo e proteggiamo le tue
                                informazioni personali. Utilizzando il nostro sito, acconsenti alla raccolta e all'uso
                                delle tue informazioni in conformità con questa politica.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">2. Informazioni che Raccogliamo</h2>
                            <p class="text-base leading-relaxed">
                                Raccogliamo informazioni personali che ci fornisci volontariamente quando utilizzi i
                                nostri servizi o ci contatti. Queste informazioni possono includere nome, indirizzo
                                email, numero di telefono e qualsiasi altra informazione che decidi di condividere con
                                noi.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">3. Uso delle Informazioni</h2>
                            <p class="text-base leading-relaxed">
                                Utilizziamo le informazioni raccolte per:
                            </p>
                            <ul class="list-disc list-inside pl-4">
                                <li>Fornire e migliorare i nostri servizi.</li>
                                <li>Rispondere alle tue richieste e domande.</li>
                                <li>Inviarti aggiornamenti e comunicazioni relative ai nostri servizi.</li>
                                <li>Analizzare l'uso del nostro sito per migliorare la nostra offerta.</li>
                            </ul>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">4. Condivisione delle Informazioni</h2>
                            <p class="text-base leading-relaxed">
                                Non condividiamo le tue informazioni personali con terze parti, eccetto quando richiesto
                                dalla legge o per proteggere i nostri diritti.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">5. Sicurezza delle Informazioni</h2>
                            <p class="text-base leading-relaxed">
                                Adottiamo misure di sicurezza adeguate per proteggere le tue informazioni personali da
                                accessi non autorizzati, alterazioni, divulgazioni o distruzioni.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">6. Diritti dell'Utente</h2>
                            <p class="text-base leading-relaxed">
                                Hai il diritto di accedere, correggere o cancellare le tue informazioni personali in
                                nostro possesso. Per esercitare questi diritti, ti preghiamo di contattarci utilizzando
                                le informazioni di contatto fornite sul sito.
                            </p>
                        </section>

                        <section class="mb-8">
                            <h2 class="text-xl font-semibold mb-2">7. Modifiche alla Policy</h2>
                            <p class="text-base leading-relaxed">
                                Ci riserviamo il diritto di modificare questa Policy & Privacy in qualsiasi momento. Le
                                modifiche saranno pubblicate su questa pagina e diventeranno effettive al momento della
                                pubblicazione.
                            </p>
                        </section>

                        <section>
                            <h2 class="text-xl font-semibold mb-2">8. Contatti</h2>
                            <p class="text-base leading-relaxed">
                                Per domande o ulteriori informazioni sulla nostra Policy & Privacy, puoi contattarci
                                all'indirizzo email
                                <a href="mailto:info@pucciefigli.com"
                                    class="text-blue-500 hover:underline">info@pucciefigli.com</a>.
                            </p>
                        </section>
                    </div>
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button" v-on:click="toggleModalPrivacy()">
                            Chiudi
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showBanner" class="fixed bottom-4 left-0 right-0 mx-4 bg-gray-900 bg-opacity-90 text-white px-4 py-4 md:py-3 flex flex-col md:flex-row items-center justify-between rounded-lg shadow-lg z-40">
                    
        <p class="text-sm md:text-base mb-4 leading-snug">
        Questo sito utilizza cookies per migliorare l'esperienza di navigazione. Continuando la navigazione accetti le 
        <a @click.prevent="toggleModalPrivacy" class="text-blue-500 underline hover:text-blue-700 transition-colors">
            nostre policy.
        </a>
        </p>

      <div class="flex justify-end space-x-3">
        <button 
          type="button" 
          @click="declineCookies" 
          class="px-4 py-2 bg-gray-600 text-gray-100 rounded hover:bg-gray-500 transition-colors">
          Rifiuta
        </button>
        <button 
          type="button" 
          @click="acceptCookies" 
          class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500 transition-colors">
          Accetta
        </button>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'CookieBanner',
  data() {
    return {
        showBanner: true,
        showModalPrivacy: false
    };
  },
  methods: {
    acceptCookies() {
      // Logic to handle cookie acceptance
      console.log('Cookies accepted');
      this.showBanner = false;
    },
    declineCookies() {
      // Logic to handle cookie rejection
        console.log('Cookies declined');

    },
    toggleModalPrivacy() {
        this.showModalPrivacy = !this.showModalPrivacy;
    }
  }
};
</script>