<template>
  <div class="whatsapp-widget">
    <a :href="whatsappLink" target="_blank" rel="noopener noreferrer" class="whatsapp-button">
      <img src="@/assets/whatsapp-icon.svg" alt="WhatsApp" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'WhatsAppWidget',
  props: {
    phoneNumber: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: ''
    }
  },
  computed: {
    whatsappLink() {
      return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.message)}`;
    }
  }
};
</script>

<style scoped>
.whatsapp-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 20;
}

.whatsapp-button img {
  width: 50px;
  height: 50px;
}
</style>