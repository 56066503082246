<template>
    <!--    <div class="flex items-center justify-center h-screen"> -->
    <div class="flex items-center justify-center">
        <video-background style="height: calc(100vh - 20px);" :src="videoSrc" class="w-full h-full">
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <div>
                    <div class="text-white font-bold text-6xl md:text-8xl">
                        Pucci<span class="text-blue-500">&</span>Figli

                    </div>
                    <!-- <h1 class="text-white font-bold text-6xl md:text-8xl">Pucci&Figli</h1>
                    <h1 class=" font-bold text-6xl md:text-8xl">&</h1>
                    <h1 class="text-white font-bold text-6xl md:text-8xl">Pucci&Figli</h1>-->
                    <span class="text-white text-xl md:text-3xl">Montaggio e forniture dal 1998</span>
                </div>
            </div>
        </video-background>
    </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import videoFile from '@/assets/video_okay.mp4'

export default {
    components: {
        VideoBackground
    },
    data() {
        return {
            videoSrc: videoFile
        }
    }
}
</script>